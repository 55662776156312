import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// import Seo from '../components/seo'
// import Layout from '../components/layout'
// import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

class CategoryTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulCategory')
    const blogs = get(this.props, 'data.allContentfulBlogPost')
    const authorBlogs = blogs.edges.map((element: { node: any }) => {return element.node})

    return (
      <div>
          {post.name}
          <ArticlePreview posts={authorBlogs} />
      </div>
    )
  }
}

export default CategoryTemplate


export const pageQuery = graphql`
  query CategoryBySlug(
    $slug: String!
  ) {
    contentfulCategory(slug: { eq: $slug }) {
      slug
      name
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {category: {slug: {eq: $slug }}}
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          image: heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          description {
            raw
          }
          metadata {
            tags {
              name
            }
          }
        }
      }
    }
  }
`
