import React from 'react'
import { Link } from 'gatsby'

import * as styles from './tags.module.css'

const createSlug = (name:String) => {
  return name.toLowerCase().replace(/ /g, '-');
}

const Tags = ({ tags }: any) =>{
  if (!tags) return null
  tags.forEach((tag:any) => tag.slug = createSlug(tag.name));
  return (
    <small className={styles.tags}>
      {tags.map((tag:any) => (
        <div key={tag.slug} className={styles.tag}>
          <Link to={`/en-gb/blog/tag/${tag.slug}`} className={styles.link}>
            {tag.name}
          </Link>
        </div>
      ))}
    </small>
    
  )
}

export default Tags
